import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
  Button,
  useTheme,
  Link,
} from '@mui/material'
import MapIcon from '@mui/icons-material/Map'
import DirectoryContactDialog from 'src/components/Dialog/DirectoryContactDialog'

const DirectoryTable = ({
  healthcareSystems,
  sortConfig = { key: 'name', direction: 'asc' },
  sortData,
}) => {
  const theme = useTheme()
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const { signedIn } = useSelector(state => state.identity)
  const openGlobalMap = () => {
    window.open(
      `https://www.google.com/maps/d/u/0/viewer?ll=43.16822275184898%2C-77.0396822523789&z=7&mid=1yP9yZeA4N0o3dQgp4KTVrgJjy9LjKEQ`,
      '_blank',
    )
  }

  return (
    <Grid
      container
      spacing={2}
      alignItems='center'
      justifyContent='center'
      sx={{
        marginBottom: 3,
        padding: '20px',
        maxWidth: '70em',
      }}
    >
      {/* Global Feedback Button */}
      {signedIn && (
        <Grid container justifyContent='space-between' sx={{ marginTop: 1, marginBottom: 1 }}>
          <Grid item>
            <Button
              variant='contained'
              startIcon={<MapIcon />} // Adding the map icon
              onClick={openGlobalMap}
              sx={{
                fontWeight: 'bold',
                background: 'linear-gradient(45deg, #0E7C7B 30%, #0E467C 90%)', // Teal to deep blue gradient
                color: '#FFF',
                padding: '12px 24px',
                fontSize: '1rem',
                borderRadius: '8px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)', // Subtle shadow for depth
                '&:hover': {
                  background: 'linear-gradient(45deg, #0B5F5E 30%, #092D55 90%)', // Darker shades on hover
                  transform: 'scale(1.05)', // Slight zoom effect
                },
                transition: 'all 0.3s ease-in-out', // Smooth hover transition
              }}
            >
              Use our Interactive Map
            </Button>
          </Grid>
          <Grid item>
            <Button variant='outlined' color='primary' onClick={() => setFeedbackOpen(true)}>
              Report Incorrect Information
            </Button>
          </Grid>
        </Grid>
      )}

      {/* Table Container */}
      <TableContainer
        sx={{
          minWidth: '1000px',
          width: '100%',
          overflowX: 'auto',
        }}
        component={Paper}
      >
        <Table>
          <TableHead sx={{ backgroundColor: theme.palette.secondary.dark }}>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>
                <TableSortLabel
                  active={sortConfig.key === 'name'}
                  direction={sortConfig.direction || 'asc'}
                  onClick={() => sortData('name')}
                  sx={{
                    color: 'white',
                    '&:hover': { color: 'white' },
                    '&.Mui-active': { color: 'white' },
                    '& .MuiTableSortLabel-icon': { color: 'white' },
                  }}
                >
                  Healthcare System
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: 'white' }}>Address</TableCell>
              <TableCell sx={{ color: 'white' }}>Phone Number</TableCell>
              <TableCell sx={{ color: 'white' }}>Recruiter Emails</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!signedIn && (
              <TableRow>
                <TableCell colSpan={4} sx={{ textAlign: 'center', padding: '20px' }}>
                  <Typography variant='body1' color='textSecondary'>
                    Finding the right job in healthcare just got easier. MyStethi now features one
                    of the <b>largest hospital directories</b>, a comprehensive list of in-house
                    physician recruiters, and an <b>interactive map</b> to help you quickly explore
                    opportunities across the country.
                  </Typography>
                  <Typography
                    variant='body1'
                    color='textSecondary'
                    sx={{ textAlign: 'left', display: 'inline-block', marginTop: 1 }}
                  >
                    ✅ Browse thousands of hospitals and healthcare systems in one place <br />
                    ✅ Connect directly with in-house recruiters for job opportunities <br />✅ Use
                    our interactive map to find hospitals and recruiters by location
                  </Typography>

                  <Typography variant='body1' color='textSecondary' sx={{ marginTop: 2 }}>
                    To respect the privacy of our users, we ask that you log in or sign up below.
                  </Typography>

                  <Link
                    href='/login'
                    underline='hover'
                    color='primary'
                    sx={{ marginRight: '20px', marginTop: '10px', display: 'inline-block' }}
                  >
                    Sign in
                  </Link>

                  <Button
                    variant='contained'
                    color='primary'
                    sx={{ mt: 1 }}
                    component={Link}
                    href='/register/physicians'
                  >
                    Sign Up
                  </Button>
                </TableCell>
              </TableRow>
            )}

            {healthcareSystems.map(system => (
              <React.Fragment key={system.id}>
                {/* Main Healthcare System Row */}
                <TableRow>
                  <TableCell>
                    <Typography variant='body1' fontWeight='bold'>
                      {system.name}
                    </Typography>
                  </TableCell>
                  <TableCell>{system.address}</TableCell>
                  <TableCell>{system.phone}</TableCell>
                  <TableCell>
                    {signedIn &&
                      system.employerUserEmails &&
                      system.employerUserEmails
                        .filter(
                          email =>
                            !system.campuses?.some(campus =>
                              (campus.employerUserEmails || []).includes(email),
                            ),
                        )
                        .map((email, index) => (
                          <Typography
                            key={index}
                            variant='body2'
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <a href={`mailto:${email}`}>{email}</a>
                          </Typography>
                        ))}
                  </TableCell>
                </TableRow>

                {/* Campus Rows */}
                {system.campuses &&
                  system.campuses.map((campus, index) => (
                    <TableRow
                      key={`${system.id}-campus-${index}`}
                      sx={{ backgroundColor: '#f9f9f9' }}
                    >
                      <TableCell>
                        <Typography variant='body2' sx={{ paddingLeft: 2 }}>
                          {campus.name}
                        </Typography>
                      </TableCell>
                      <TableCell>{campus.address}</TableCell>
                      <TableCell>{campus.phone}</TableCell>
                      <TableCell>
                        {signedIn &&
                          (campus.employerUserEmails || []).map((email, index) => (
                            <Typography
                              key={index}
                              variant='body2'
                              sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              <a href={`mailto:${email}`}>{email}</a>
                            </Typography>
                          ))}
                      </TableCell>
                    </TableRow>
                  ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DirectoryContactDialog feedbackOpen={feedbackOpen} setFeedbackOpen={setFeedbackOpen} />
    </Grid>
  )
}

export default DirectoryTable
