import { fetchDispatch } from 'data/helpers'
import { showSnackbar } from 'data/snackbar/actions'

export const POST_NEWSLETTER_SUBSCRIPTION = "POST_NEWSLETTER_SUBSCRIPTION"
export const POST_NEWSLETTER_SUBSCRIPTION_SUCCESS = "POST_NEWSLETTER_SUBSCRIPTION_SUCCESS"

export const postNewsletterSubscriptionAction = () => {
  return {
    type: POST_NEWSLETTER_SUBSCRIPTION
  }
}

export const postNewsletterSubscriptionSuccess = () => {
  return {
    type: POST_NEWSLETTER_SUBSCRIPTION_SUCCESS,
  }
}

export const postNewsletterSubscription = (requestVariables = {}) => {
  return async function postNewsletterSubscriptionThunk(dispatch) {
    // show loader
    dispatch(postNewsletterSubscriptionAction())
    await fetchDispatch({ path: '/api/newsletter_subscriptions', values: requestVariables })
    showSnackbar(dispatch, "Successfully submitted, we'll be in touch!")
    return dispatch(postNewsletterSubscriptionSuccess())
  }
}
